<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogAddItem"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
      scrollable
    >
      <v-card>
        <v-toolbar max-height="65" v-if="ppmpData.PPMPID" dark color="green darken-2">
          <v-toolbar-title
            >{{ ppmpData.PPMPDescription }}
            <span v-if="ppmpData.PPMPTypeID == 1">(DBM)</span>
            <span v-if="ppmpData.PPMPTypeID == 2">(NON-DBM)</span>
            <span v-if="ppmpData.PPMPTypeID  == 3">(LIB)</span>
            </v-toolbar-title
          >
          <v-spacer></v-spacer>
          <span style="width: 300px">
            Total Balances : ₱ {{ formatPrice(ppmpData.Amount) }} <br />
            Available Balance : ₱
            {{
              formatPrice(
                parseFloat(ppmpData.Amount) - parseFloat((parseFloat(ppmpUsedAmount_ndbm) + parseFloat(ppmpUsedAmount_lib)).toFixed(2))
              )
            }}
          </span>
          <v-btn icon dark @click="clickCloseMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                :complete="ppmpData.RecommendingApprovalStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Recommending Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.RecommendingApprovalDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="2"
                :complete="ppmpData.BudgetStatusID == 7"
                class="pa-3"
              >
              <span class="text-no-wrap">Budget Officer Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.BudgetApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                step="3"
                :complete="ppmpData.FinanceStatusID == 7"
                class="pa-3"
              >
                <span class="text-no-wrap">Funds Availability</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.FinanceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="ppmpData.PresidentStatusID == 7"
                step="4"
                class="pa-3"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(ppmpData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>

        <v-alert
          v-if="ppmpData.isPending"
          border="left"
          type="error"
          class="white--text amber accent-4"
          prominent
          dense
        >
          <div v-if="ppmpData.RecommendingApprovalRemarks"> <strong>Recommending Approval: </strong>{{ ppmpData.RecommendingApprovalRemarks }}</div>
          <div v-if="ppmpData.BudgetRemarks"> <strong>Budget Officer: </strong>{{ ppmpData.BudgetRemarks }}</div>
          <div v-if="ppmpData.FinanceRemarks"> <strong>Finance Director: </strong>{{ ppmpData.FinanceRemarks }}</div>
          <div v-if="ppmpData.PresidentRemarks"> <strong>College President: </strong>{{ ppmpData.PresidentRemarks }}</div>
        </v-alert>
        <v-card-text style="max-height: 900px">
        <v-row class="mt-1">
          <v-col cols="12" class="py-4">
            <v-row>
              <v-col cols="12">
                <v-card
                class="mx-auto px-5 py-2 font-size-18"
              >
              <span
                      >
                      Legend: 
                      <v-chip
                        class="ma-2"
                        outlined
                      >
                        <v-avatar
                          left
                          class="teal lighten-5"
                        >
                        </v-avatar>
                        Utilized with balance
                      </v-chip>
                      <v-chip
                        class="ma-2"
                         outlined
                      >
                        <v-avatar
                          left
                          class="deep-orange lighten-5"
                        >
                        </v-avatar>
                        Utilized Meals/Snacks
                      </v-chip>
                      <v-chip
                        class="ma-2"
                         outlined
                      >
                        <v-avatar
                          left
                          class="light-green lighten-5"
                        >
                        </v-avatar>
                        Requested & Unprocessed
                      </v-chip>
                      </span>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card class="">
              <v-data-table
                v-model="itemsCheckedForRevision"
                :loading="loading"
                :headers="headers"
                :items="itemList_ndbm"
                :footer-props="footerProps"
                :item-class="itemRowBackground"
                item-key="ppmpItemID"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >
                      Non-DBM Item List
                      </span>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      class="white--text"
                      color="green"
                      @click="reviseAllNDBM()"
                      :disabled="checkItemRevLength(2) == 0"
                      v-if="itemsCheckedForRevision_ndbm.length < checkItemRevLength(2)"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Revise All
                    </v-btn>
                    <v-btn
                      class="white--text ml-2"
                      color="red"
                      @click="cancelReviseAllNDBM()"
                      :disabled="checkItemRevLength(2) == 0"
                      v-if="itemsCheckedForRevision_ndbm.length > 0"
                    >
                      <v-icon>mdi-close</v-icon>
                      Cancel All
                    </v-btn> -->
                  </v-toolbar>
                </template>
                <!-- <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.ppmpItemID" 
                    :style="parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && item.CategoryID == 21 ? 'background: #fff6e6;'
                            :parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) ?  'background: #eee;'
                            : ''">
                      <td>
                        <v-checkbox
                          v-model="itemsCheckedForRevision"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                          v-if="item.PRstatus == 0 && item.isRevised == 0"
                          :disabled="checkBalance(item)"
                        />
                        <v-checkbox
                          v-model="itemsCheckedForRevision"
                          :value="item"
                          style="margin: 0px; padding: 0px"
                          hide-details
                          v-if="parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && item.isRevised == 0 && item.CategoryID != 21"
                          :disabled="checkBalance(item)"
                        />
                      </td>
                      <td style="max-width: 350px">{{ item.ItemName }}</td>
                      <td>{{ item.Qty }}</td>
                      <td>{{ item.UnitMeasureDescription }}</td>
                      <td>₱ {{ formatPrice(item.UnitCost) }}</td>
                      <td>
                        ₱
                        {{
                          formatPrice(
                            parseFloat(item.UnitCost) * parseFloat(item.Qty)
                          )
                        }}
                      </td>
                      <td>
                        {{formatPrice(item.Utilized)}}
                      </td>
                      <td>
                        {{ formatPrice(getObligationAmount(item)) }}
                      </td>
                      <td>
                        {{formatPrice(((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(getObligationAmount(item))) - parseFloat(item.Utilized))}}
                      </td>
                      <td align="center">
                        <v-chip small dark outlined color="green" v-if="item.PRstatus != 0 || item.isRevised != 0">
                        <v-icon
                        small
                          left
                          color="green"
                          >mdi-check-circle</v-icon>{{ item.isRevised ? 'Revised' : 'Requested'}}
                        </v-chip>
                      </td>
                    </tr>
                  </tbody>
                </template> -->
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    v-if="item.isSelectable"
                    :disabled="checkBalance(item)"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                    {{formatQty(item.Qty)}}
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                   <span class="text-no-wrap"> ₱ {{formatPrice(item.UnitCost)}}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.Utilized`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(item.Utilized)}}</span>
                </template>
                <template v-slot:[`item.Obligation`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(getObligationAmount(item)) }}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                   <span class="text-no-wrap">₱ {{formatPrice(item.Balance)}}</span>
                </template>
                <template v-slot:[`item.RequestedOrRevised`]="{ item }">
                  <v-chip x-small dark outlined color="green" v-if="item.PRstatus != 0 || item.isRevised != 0">
                  <v-icon
                    x-small
                    left
                    color="green"
                    >mdi-check-circle</v-icon>{{ item.isRevised ? 'Revised' : 'Requested'}}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
              </v-col>
              <v-col cols="12">
                <v-card class="">
              <v-data-table
                v-model="itemsCheckedForRevision"
                :loading="loading"
                :headers="headers"
                :items="itemList_lib"
                :footer-props="footerProps"
                :item-class="itemRowBackground"
                item-key="ppmpItemID"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >
                      LIB Item List
                      </span>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      class="white--text mr-2"
                      color="green"
                      @click="reviseAllLIB()"
                      :disabled="checkItemRevLength(3) == 0"
                      v-if="itemsCheckedForRevision_lib.length < checkItemRevLength(3)"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Revise All
                    </v-btn>
                    <v-btn
                      class="white--text mr-2"
                      color="red"
                      @click="cancelReviseAllLIB()"
                      :disabled="checkItemRevLength(3) == 0"
                      v-if="itemsCheckedForRevision_lib.length > 0"
                    >
                      <v-icon>mdi-close</v-icon>
                      Cancel All
                    </v-btn> -->
                  </v-toolbar>
                </template>
                <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :ripple="false"
                    v-if="item.isSelectable"
                    :disabled="checkBalance(item)"
                    @input="select($event)"
                  ></v-simple-checkbox>
                </template>
                <template v-slot:[`item.Qty`]="{ item }">
                    {{formatQty(item.Qty)}}
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                   <span class="text-no-wrap"> ₱ {{formatPrice(item.UnitCost)}}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                </template>
                <template v-slot:[`item.Utilized`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(item.Utilized)}}</span>
                </template>
                <template v-slot:[`item.Obligation`]="{ item }">
                   <span class="text-no-wrap">₱ {{ formatPrice(getObligationAmount(item)) }}</span>
                </template>
                <template v-slot:[`item.Balance`]="{ item }">
                   <span class="text-no-wrap">₱ {{formatPrice(item.Balance)}}</span>
                </template>
                <template v-slot:[`item.RequestedOrRevised`]="{ item }">
                  <v-chip x-small dark outlined color="green" v-if="item.PRstatus != 0 || item.isRevised != 0">
                  <v-icon
                    x-small
                    left
                    color="green"
                    >mdi-check-circle</v-icon>{{ item.isRevised ? 'Revised' : 'Requested'}}
                  </v-chip>
                </template>
              </v-data-table>
            </v-card>
              </v-col>
            </v-row>
          </v-col>
          
        </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addToPPMP" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="font-weight-bold" v-if="editItemId != null"
            >Update Item</span
          >
          <span class="font-weight-bold" v-else>Add Item</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <span v-if="editItemId != null">
                    <v-list-item-content>
                      <v-list-item-title
                        ><b>{{ formData.ItemName }}</b></v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        formData.itemDescription
                      }}</v-list-item-subtitle>
                      <span
                        >₱ {{ formatPrice(formData.UnitCost) }} /
                        {{ formData.UnitMeasureDescription }}</span
                      >
                    </v-list-item-content>
                    <v-divider></v-divider>
                    <br />
                  </span>
                  <v-autocomplete
                    v-else
                    v-model="formData.ItemID"
                    color="green"
                    item-text="ItemName"
                    item-value="ItemID"
                    :rules="[formRules.required]"
                    :items="items"
                    :loading="itemsisLoading"
                    :search-input.sync="search"
                    dense
                    label="Item"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for an Item
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.ItemName
                          }}</v-list-item-title>
                          <v-list-item-subtitle>{{
                            data.item.ItemDescription
                          }}</v-list-item-subtitle>
                          <span
                            >₱ {{ formatPrice(data.item.UnitCost) }} /
                            {{ data.item.UnitMeasureDescription }}</span
                          >
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    outlined
                    dense
                    color="green"
                    style="width: 190px"
                    :label="editPPMPType == 3 ? 'Amount' : 'Quantity'"
                    :rules="[checkQtyandBalance(), formRules.required, formData.Qty > 0]"
                    v-model="formData.Qty"
                    class="pa-0 mt-4"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <span><strong>Available Balance :</strong> ₱ {{formatPrice(parseFloat(ppmpRevisedAmount) - parseFloat(ppmpRevisedUsedAmount))}}</span>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAddItem()"> Cancel </v-btn>
          <v-btn
            v-if="editItemId == null"
            :loading="loadingBtn"
            color="green darken-2"
            class="white--text"
            @click="addItemToRevisedPPMP()"
          >
            Add Item
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            class="white--text"
            @click="updateRevisedItem()"
            :loading="loadingBtn"
          >
            Update Item
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectSchedDialog" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="">SCHEDULE/ MILESTONE OF ACTIVITIES </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent ref="Formref2">
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    color="green"
                    label="Select Month"
                    v-model="formData.DateSchedules"
                    :items="filteredMonths"
                    item-value="month"
                    item-text="description"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelAddItem()">
            Cancel
          </v-btn>
          <v-btn
            :loading="loadingBtn"
            color="green darken-2"
            class="white--text"
            @click="SaveDate()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
          v-model="addPPMPDialog"
          persistent
          max-width="450px"
        >
          <v-card>
            <v-card-title>
              <span class="subtitle-1 font-weight-bold">Create PPMP</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="addPPMPDialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="FormRefPublish">
            <v-card-text>
              <v-container>
              <v-row>
              <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
            <v-text-field
            dense
              v-model="ppmpDescription"
              :rules="[formRules.required]"
              label="PPMP Description"
              color="green"
              class="mb-4"
            ></v-text-field>
              <v-autocomplete
                  v-model="approvalID"
                  color="green"
                  item-text="FirstName"
                  item-value="UserID"
                  :rules="[formRules.required]"
                  :items="approvalList"
                  dense
                  class="mb-4"
                  label="Recommending Approval">

              <template v-slot:selection="data">
                 {{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}} {{data.item.EducQuali}} 
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{data.item.FirstName}} {{data.item.MiddleName}} {{data.item.LastName}} {{data.item.EducQuali}} </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
          </v-autocomplete>
        </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="addPPMPDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="publish"
                :loading="isLoading"
              >
                Publish
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-dialog v-model="confirmCloseDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <div class="mx-6">
              All added items will be removed. <br /> Are you sure you want to cancel the revision?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="confirmCloseDialog = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmClickCloseMainDialog()">Continue</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmPublishDialog" scrollable max-width="2000px">
          <v-card>
            <v-card-title class="headline">
              Confirmation
              <v-spacer></v-spacer>
              <v-btn icon @click="confirmPublishDialog = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
            <v-card-text class="pa-0" style="max-height: 900px;">
            <div class="mx-6">
              <v-row>
                <v-col cols="12" class="d-flex justify-center my-3">
                  <h2>REVISION SUMMARY</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-toolbar flat class="font-size-17" max-height="50">
                        <strong>OLD ITEMS (SELECTED)</strong>
                        <v-spacer></v-spacer>
                        <strong>Grand Total : </strong> ₱ {{ formatPrice(parseFloat(itemsCheckedForRevision_ndbm_total) + parseFloat(itemsCheckedForRevision_lib_total)) }}
                      </v-toolbar>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :loading="loading"
                        :headers="headers_summary"
                        :items="itemsCheckedForRevision_ndbm"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <span class="green--text text--darken-2"
                              >Non-DBM Items</span
                            >
                            <v-spacer></v-spacer>
                            <strong>Total Balance : </strong> ₱ {{ formatPrice(itemsCheckedForRevision_ndbm_total) }}
                          </v-toolbar>
                        </template>
                    <template v-slot:[`item.Qty`]="{ item }">
                        {{formatQty(item.Qty)}}
                    </template>
                    <template v-slot:[`item.UnitCost`]="{ item }">
                      <span class="text-no-wrap"> ₱ {{formatPrice(item.UnitCost)}}</span>
                    </template>
                    <template v-slot:[`item.Amount`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                    </template>
                    <template v-slot:[`item.Balance`]="{ item }">
                      <span class="text-no-wrap">₱ {{formatPrice(((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(getObligationAmount(item))) - parseFloat(item.Utilized))}}</span>
                    </template>
                    </v-data-table>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :loading="loading"
                        :headers="headers_summary"
                        :items="itemsCheckedForRevision_lib"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <span class="green--text text--darken-2"
                              >LIB Items</span
                            >
                            <v-spacer></v-spacer>
                            <strong>Total Balance : </strong> ₱ {{ formatPrice(itemsCheckedForRevision_lib_total) }}
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.Qty`]="{ item }">
                        {{formatQty(item.Qty)}}
                    </template>
                    <template v-slot:[`item.UnitCost`]="{ item }">
                      <span class="text-no-wrap"> ₱ {{formatPrice(item.UnitCost)}}</span>
                    </template>
                    <template v-slot:[`item.Amount`]="{ item }">
                      <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                    </template>
                    <template v-slot:[`item.Balance`]="{ item }">
                      <span class="text-no-wrap">₱ {{formatPrice(((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(getObligationAmount(item))) - parseFloat(item.Utilized))}}</span>
                    </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6">
                  <v-row>
                    <v-col cols="12">
                      <v-toolbar flat class="font-size-17" max-height="50">
                      <strong>NEW ITEMS</strong>
                      <v-spacer></v-spacer>
                      <strong>Grand Total : </strong> ₱ {{ formatPrice(parseFloat(itemsForRevision_ndbm_total) + parseFloat(itemsForRevision_lib_total)) }}
                      </v-toolbar>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :loading="loading"
                        :headers="headers_revision_summary"
                        :items="itemsForRevision_ndbm"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <span class="green--text text--darken-2"
                              >Non-DBM Items</span
                            >
                            <v-spacer></v-spacer>
                            <strong>Total Balance : </strong> ₱ {{ formatPrice(itemsForRevision_ndbm_total) }}
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.Qty`]="{ item }">
                            {{formatQty(item.Qty)}}
                        </template>
                        <template v-slot:[`item.UnitCost`]="{ item }">
                          <span class="text-no-wrap"> ₱ {{formatPrice(item.UnitCost)}}</span>
                        </template>
                        <template v-slot:[`item.Amount`]="{ item }">
                          <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12">
                      <v-data-table
                        :loading="loading"
                        :headers="headers_revision_lib_summary"
                        :items="itemsForRevision_lib"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <span class="green--text text--darken-2"
                              >LIB Items</span
                            >
                            <v-spacer></v-spacer>
                            <strong>Total Balance : </strong> ₱ {{ formatPrice(itemsForRevision_lib_total) }}
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.Amount`]="{ item }">
                          <span class="text-no-wrap">₱ {{ formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            </v-card-text>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="confirmPublishDialog = false">Cancel</v-btn>
              <v-btn color="blue darken-4 white--text px-3" @click="confirmPublish()"><v-icon>mdi-check</v-icon> Publish</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="confirmDeleteDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to delete this item?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="confirmDeleteDialog = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmDeleteItem()">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    ppmpData: {},
  },
  data: () => ({
    ppmpDetails: [],
    itemsisLoading: false,
    items: [],
    search: null,
    ppmpUsedAmount_ndbm: null,
    ppmpUsedAmount_lib: null,
    headers: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName"
      },
      {
        text: "Item Description",
        align: "start",
        sortable: false,
        value: "itemDescription",
        width: 150,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty", value: "Qty", sortable: false, align: "center" },
      // { text: "Unit", value: "UnitMeasureDescription", sortable: false },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Utilized", value: "Utilized", sortable: false, align: "end" },
      { text: "Obligation", value: "Obligation", sortable: false, align: "end" },
      { text: "Balance", value: "Balance", sortable: false, align: "end", width: 30 },
      { text: "Status", value: "RequestedOrRevised", sortable: false, align: "center", },
    ],
    headers_summary: [
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName"
      },
      {
        text: "Item Description",
        align: "start",
        sortable: false,
        value: "itemDescription",
        width: 150,
      },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: "end" },
      { text: "Qty", value: "Qty", sortable: false, align: "center" },
      // { text: "Unit", value: "UnitMeasureDescription", sortable: false },
      { text: "Amount", value: "Amount", sortable: false, align: "end" },
      { text: "Balance", value: "Balance", sortable: false, align: "end", width: 30 },
    ],
    headers_revision: [
      { text: "Schedule", value: "DateSchedules", sortable: false },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Qty", value: "Qty", sortable: false, align: 'center' },
      { text: "Unit", value: "UnitMeasureDescription", sortable: false, align: 'center' },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: 'end' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Action", value: "action", sortable: false },
    ],
    headers_revision_summary: [
      { text: "Schedule", value: "DateSchedules", sortable: false },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Qty", value: "Qty", sortable: false, align: 'center' },
      { text: "Unit", value: "UnitMeasureDescription", sortable: false, align: 'center' },
      { text: "Unit Cost", value: "UnitCost", sortable: false, align: 'end' },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' }
    ],
    headers_revision_lib: [
      { text: "Schedule", value: "DateSchedules", sortable: false },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' },
      { text: "Action", value: "action", sortable: false },
    ],
    headers_revision_lib_summary: [
      { text: "Schedule", value: "DateSchedules", sortable: false },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      { text: "Amount", value: "Amount", sortable: false, align: 'end' }
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    filteredMonths: [],
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    addToPPMP: false,
    addPPMPDialog: false,
    loading: false,
    ppmp: [],
    dialogAddItem: false,
    footerProps: { "items-per-page-options": [5, 10, 20, 50, 100, 500] },
    isLoading: false,
    itemList_ndbm: [],
    itemList_lib: [],
    formData: {
      ppmpID: null,
      ItemID: null,
      Qty: null,
      ItemName: null,
      UnitCost: null,
      UnitMeasureDescription: null,
      itemDescription: null,
      DateSchedules: null,
    },
    ppmpDescription: null,
    approvalID: null,
    loadingBtn: false,
    editItemId: null,
    editItemQty: null,
    editPPMPType: null,
    selectSchedDialog: false,
    dialogConfirmUnpublish: false,
    toUnpublishPPMPID: null,
    itemsForRevision: [],
    itemsForRevision_ndbm: [],
    itemsForRevision_ndbm_total: 0,
    itemsForRevision_lib: [],
    itemsForRevision_lib_total: 0,
    itemsCheckedForRevision: [],
    itemsCheckedForRevision_ndbm: [],
    itemsCheckedForRevision_ndbm_total: 0,
    itemsCheckedForRevision_lib: [],
    itemsCheckedForRevision_lib_total: 0,
    ppmpRevisedAmount: 0,
    ppmpRevisedUsedAmount: 0,
    budgetList: [],
    approvalList: [],
    obligations: [],
    confirmCloseDialog: false,
    confirmPublishDialog: false,
    confirmDeleteDialog: false,
  }),
  watch: {
    search(val) {
      this.itemsisLoading = true;
      setTimeout(() => {
        if (val == this.search) {
          this.SearchItem();
        }
      }, 2000);
    },
    ppmpData: {
      handler(data) {
        if (data.PPMPID) {
          this.itemsCheckedForRevision = [];
          this.itemsForRevision = [];
          this.itemsForRevision_ndbm = [];
          this.itemsForRevision_lib = [];
          this.ppmpRevisedAmount = parseFloat(data.Amount)
          this.getItems();
          this.getItems_lib();
          this.getFilteredMonths(data.BudgetDate, data.BudgetDateEnd)
          this.dialogAddItem = true;
          console.log(data)
        }
      },
      deep: true,
    },
    itemsCheckedForRevision: {
      handler(data) {
        this.itemsCheckedForRevision_ndbm = [];
        this.itemsCheckedForRevision_ndbm_total = 0;
        this.itemsCheckedForRevision_lib = [];
        this.itemsCheckedForRevision_lib_total = 0;
        this.ppmpRevisedAmount = parseFloat(this.ppmpData.Amount) - (parseFloat(this.ppmpUsedAmount_ndbm) + parseFloat(this.ppmpUsedAmount_lib));
        data.forEach(item => {
            let amount = ((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(this.getObligationAmount(item))) - parseFloat(parseFloat(item.Utilized).toFixed(2))
            if(item.PRstatus == 0 || parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && parseFloat(item.Utilized) > 0){
              this.ppmpRevisedAmount = parseFloat(this.ppmpRevisedAmount) + parseFloat(amount);
            }
            if(item.PPMPTypeID == 2){
              this.itemsCheckedForRevision_ndbm.push(item)
              this.itemsCheckedForRevision_ndbm_total = this.itemsCheckedForRevision_ndbm_total + parseFloat(amount);
            }
            else if(item.PPMPTypeID == 3){
              this.itemsCheckedForRevision_lib.push(item)
              this.itemsCheckedForRevision_lib_total = this.itemsCheckedForRevision_lib_total + parseFloat(amount);
            }
          });
        this.ppmpRevisedAmount = parseFloat(parseFloat(this.ppmpRevisedAmount).toFixed(2));
      },
      deep: true,
    },
    itemsForRevision: {
      handler(data) {
        this.ppmpRevisedUsedAmount = 0.00;
        this.itemsForRevision_ndbm = [];
        this.itemsForRevision_ndbm_total = 0;
        this.itemsForRevision_lib = [];
        this.itemsForRevision_lib_total = 0;
        data.forEach(item => {
            let amount = (item.UnitCost * item.Qty).toFixed(2)
            this.ppmpRevisedUsedAmount = parseFloat(this.ppmpRevisedUsedAmount) + parseFloat(amount);
            if(item.PPMPTypeID == 2){
              this.itemsForRevision_ndbm.push(item)
              this.itemsForRevision_ndbm_total = this.itemsForRevision_ndbm_total + parseFloat(amount);
            }
            else if(item.PPMPTypeID == 3){
              this.itemsForRevision_lib.push(item)
              this.itemsForRevision_lib_total = this.itemsForRevision_lib_total + parseFloat(amount);
            }
          });
      },
      deep: true,
    },
  },
  created () {
      this.getApprovalList();
      this.setMonths();
  },
  mounted() {
        this.itemsCheckedForRevision = [];
        this.itemsForRevision = [];
        this.itemsForRevision_ndbm = [];
        this.itemsForRevision_lib = [];
  },
  methods: {
    setMonths(){
      let d = new Date();
      let list = [];
      this.months.forEach((month, index) => {
        if(index >= d.getMonth()){
         list.push(month);
        }
      });
      this.months = list;
    },
    swithcChange() {
      let data = new FormData();
      data.append("PPMPID", this.ppmpData.PPMPID);
      data.append("swithcVal", this.ppmpData.isOpen);
      this.axiosCall("/signatories/PPMP/open/close", "POST", data).then(
        (res) => {
          console.log(res);
        }
      );
    },
    reviseAllNDBM(){
      this.itemsCheckedForRevision.filter(( obj ) => {
          return obj.PPMPTypeID != 2;
      });
        this.itemList_ndbm.forEach(item => {
        if(item.PRstatus == 0 && item.isRevised == 0){
          this.itemsCheckedForRevision.push(item);
        }
        else if(parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && item.isRevised == 0 && item.CategoryID != 21){
          this.itemsCheckedForRevision.push(item);
        }
      });
    },
    reviseAllLIB(){
      this.itemsCheckedForRevision.filter(( obj ) => {
          return obj.PPMPTypeID != 3;
      });

        this.itemList_lib.forEach(item => {
        if(item.PRstatus == 0 && item.isRevised == 0){
          this.itemsCheckedForRevision.push(item);
        }
        else if(parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && item.isRevised == 0 && item.CategoryID != 21){
          this.itemsCheckedForRevision.push(item);
        }
      });
    },
    checkItemRevLength(ppmptype){
      let data = [];
      if(ppmptype == 2){
        this.itemList_ndbm.forEach(item => {
        if(item.isSelectable){
          data.push(item);
        }
      });
      }
      else if(ppmptype == 3){
        this.itemList_lib.forEach(item => {
        if(item.isSelectable){
          data.push(item);
        }
      });
      }
      
      return data.length;
    },
    cancelReviseAllNDBM(){
      this.itemsCheckedForRevision = this.itemsCheckedForRevision.reduce((p,c) => (c.PPMPTypeID !== 2 && p.push(c),p),[]);
    },
    cancelReviseAllLIB(){
      this.itemsCheckedForRevision = this.itemsCheckedForRevision.reduce((p,c) => (c.PPMPTypeID !== 3 && p.push(c),p),[]);
    },
    checkBalance(item){
       if(parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpRevisedUsedAmount) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && this.itemsCheckedForRevision.indexOf(item) > -1 && this.itemsForRevision.length > 0){
          return true;
        }
        else{
          return false;
        }
    },
    deleteItem(item){
      this.itemToDelete = item;
      this.confirmDeleteDialog = true
    },
    confirmDeleteItem(){
      let index = this.itemsForRevision.indexOf(this.itemToDelete);
      if (index > -1) {
        this.itemsForRevision.splice(index, 1);
        this.confirmDeleteDialog = false;
      }
    },
    addItemToRevisedPPMP(){
      if (this.$refs.Formref.validate()) {
        let UnitCost = 0;
        let ItemName = '';
        let ItemDescription = '';
        let UnitMeasureDescription = '';
        let Qty = parseFloat(this.formData.Qty);
      this.items.forEach((element) => {
          if (element.ItemID == this.formData.ItemID) {
            UnitCost = parseFloat(element.UnitCost);
            ItemName = element.ItemName;
            ItemDescription = element.ItemDescription;
            UnitMeasureDescription = element.UnitMeasureDescription;
          }
        });
      this.itemsForRevision.push(
        {
          "ItemName": ItemName,
          "ItemDescription": ItemDescription,
          "Qty": parseFloat(Qty.toFixed(2)),
          "UnitMeasureDescription": UnitMeasureDescription,
          "ppmpID": this.ppmpData.PPMPID,
          "ItemID": this.formData.ItemID,
          "UnitCost": parseFloat(UnitCost.toFixed(2)),
          "DateSchedules": this.formData.DateSchedules,
          "PPMPTypeID": this.editPPMPType
        }
      );
      this.$refs.Formref.reset();
      this.$refs.Formref2.reset();
      this.items = [];
      this.addToPPMP = false;
      }
    },
    SaveDate() {
      if (this.formData.DateSchedules) {
        this.selectSchedDialog = false;
        this.addToPPMP = true;
      }
    },
    cancelAddItem(){
      this.selectSchedDialog = false;
      this.$refs.Formref.reset();
      this.$refs.Formref2.reset();
      this.items = [];
      this.addToPPMP = false;
      this.selectSchedDialog = false;
    },
    addItemBtnNDBM() {
      this.editItemId = null;
      this.editPPMPType = 2;
      if (this.ppmpData.isDBM) {
        this.formData.DateSchedules = "January";
        this.addToPPMP = true;
      } else {
        this.selectSchedDialog = true;
      }
    },
    addItemBtnLIB() {
      this.editItemId = null;
      this.editPPMPType = 3;
      if (this.ppmpData.isDBM) {
        this.formData.DateSchedules = "January";
        this.addToPPMP = true;
      } else {
        this.selectSchedDialog = true;
      }
    },
    editItem(data) {
        let index = this.itemsForRevision.indexOf(data);
        this.editItemQty = data.Qty;
        this.editItemId = index;
        this.formData.Qty = data.Qty;
        this.formData.ItemName = data.ItemName;
        this.formData.UnitCost = data.UnitCost;
        this.formData.UnitMeasureDescription = data.UnitMeasureDescription;
        this.formData.itemDescription = data.itemDescription;
        this.formData.ItemID = data.ItemID;
        this.formData.DateSchedules = data.DateSchedules;
        this.selectSchedDialog = true;
    },
    updateRevisedItem(){
      if (this.$refs.Formref.validate()) {
      let item = this.itemsForRevision[this.editItemId];
          item.Qty = this.formData.Qty;
          item.DateSchedules = this.formData.DateSchedules;
      this.$refs.Formref.reset();
      this.$refs.Formref2.reset();
      this.editItemId = null;
      this.editPPMPType = null;
      this.addToPPMP = false;
      }
    },
    checkQtyandBalance() {
      let balance = parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpRevisedUsedAmount);
      let itemID = this.formData.ItemID;
      let qty = this.formData.Qty;
      if (!itemID) {
        return true;
      }
      let itemAmount = 0;
      if (this.editItemId) {
        itemAmount =
          parseFloat(this.formData.UnitCost) * parseFloat(this.formData.Qty) -
          parseFloat(this.formData.UnitCost) * parseFloat(this.editItemQty);
      } else {
        this.items.forEach((element) => {
          if (element.ItemID == itemID) {
            itemAmount = element.UnitCost * qty;
          }
        });
      }
      if (balance && itemAmount) {
        balance = balance.toFixed(2);
        itemAmount = itemAmount.toFixed(2);
      }
      return (
        parseFloat(balance) >= parseFloat(itemAmount) || "Insufficient Balance"
      );
    },
    SearchItem() {
      let data = new FormData();
      data.append("search", this.search);
      data.append("CategoryTypeID", this.editPPMPType);
      this.axiosCall("/search/new/item", "POST", data).then((res) => {
        this.items = res.data.data.result;
        this.itemsisLoading = false;
        this.$forceUpdate();
      });
    },
    clickCloseMainDialog() {
      if(this.itemsForRevision.length > 0){
        this.confirmCloseDialog = true;
      }
      else{
        this.confirmClickCloseMainDialog();
      }
    },
    confirmClickCloseMainDialog() {
      this.formData.DateSchedules = null;
      this.items = [];
      this.itemList_ndbm = [];
      this.itemList_lib = [];
      this.dialogAddItem = false;
      this.confirmPublishDialog = false;
      this.confirmCloseDialog = false;
      this.itemsForRevision = [];
      this.itemsCheckedForRevision = [];
      this.ppmpRevisedAmount = 0;
      this.ppmpRevisedUsedAmount = 0;
      this.ppmpUsedAmount_ndbm = [];
      this.ppmpDetails_ndbm = [];
      this.obligations_ndbm = [];
      this.itemList_lib = [];
      this.ppmpUsedAmount_lib = [];
      this.ppmpDetails_lib = [];
      this.obligations_lib = [];
      this.eventHub.$emit("closePPMPModalRevision", false);
    },
    initialize() {
      this.loading = true;
      this.axiosCall("/get/PPMP", "GET").then((res) => {
        this.loading = false;
        this.ppmp = res.data.data.result;
      });
    },
    getItems() {
      let ppmp = this.ppmpData.PPMPID.split(",");
      let type = this.ppmpData.PPMPTypeID.split(",");
      let ppmpid = '';
      ppmp.forEach((item, index) => {
        if(type[index] == 2){
          ppmpid = item
        }
      });
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 100000);
      data.append("BudgetID", this.ppmpData.BudgetID);
      data.append("PPMPID", ppmpid);
       data.append("isDBM", this.ppmpData.isDBM);
      this.axiosCall("/getPPMPItem", "POST", data).then((res) => {
        this.loading = false;
        this.itemList_ndbm = res.data.data.result;
        this.ppmpUsedAmount_ndbm = parseFloat((res.data.data.useAmount).toFixed(2));
        // console.log(this.ppmpUsedAmount_ndbm)
        this.ppmpDetails_ndbm = res.data.data.details;
        this.obligations_ndbm = res.data.data.obligations;
        this.itemList_ndbm.forEach(item => {
          item.Balance = parseFloat((((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(this.getObligationAmount(item))) - parseFloat(item.Utilized)).toFixed(2));
        });
        if(this.ppmpDetails_ndbm.isRevised == 1){
          this.obligations_ndbm.forEach(item => {
          this.ppmpUsedAmount_ndbm = this.ppmpUsedAmount_ndbm + parseFloat(parseFloat((item.Amount)).toFixed(2));
        });
        }
        this.ppmpRevisedAmount = parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpUsedAmount_ndbm);
      });
    },
    getItems_lib() {
      let ppmp = this.ppmpData.PPMPID.split(",");
      let type = this.ppmpData.PPMPTypeID.split(",");
      let ppmpid = '';
      ppmp.forEach((item, index) => {
        if(type[index] == 3){
          ppmpid = item
        }
      });
      let data = new FormData();
      data.append("page", 1);
      data.append("itemsPerPage", 100000);
      data.append("BudgetID", this.ppmpData.BudgetID);
      data.append("PPMPID", ppmpid);
      data.append("isDBM", this.ppmpData.isDBM);
      this.axiosCall("/getPPMPItem", "POST", data).then((res) => {
        this.loading = false;
        this.itemList_lib = res.data.data.result;
        this.ppmpUsedAmount_lib = parseFloat((res.data.data.useAmount).toFixed(2));
        // console.log(this.ppmpUsedAmount_lib)
        this.ppmpDetails_lib = res.data.data.details;
        this.obligations_lib = res.data.data.obligations;
        this.itemList_lib.forEach(item => {
          item.Balance = parseFloat((((parseFloat(item.UnitCost) * parseFloat(item.Qty)) - parseFloat(this.getObligationAmount(item))) - parseFloat(item.Utilized)).toFixed(2));
        });
        if(this.ppmpDetails_lib.isRevised == 1){
        this.obligations_lib.forEach(item => {
          this.ppmpUsedAmount_lib = this.ppmpUsedAmount_lib + parseFloat(parseFloat((item.Amount)).toFixed(2));
        });
        }
        this.ppmpRevisedAmount = parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpUsedAmount_lib);
      });
    },
    publish(){
    if(this.$refs.FormRefPublish.validate() && parseFloat((parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpRevisedUsedAmount)).toFixed(2)) <= 0){
      this.confirmPublishDialog = true
    }
    },
    confirmPublish() {
      if(this.$refs.FormRefPublish.validate() && parseFloat((parseFloat(this.ppmpRevisedAmount) - parseFloat(this.ppmpRevisedUsedAmount)).toFixed(2)) <= 0){
      let data = new FormData();
      data.append("PPMPID", this.ppmpData.PPMPID);
      data.append("PPMPDescription", this.ppmpDescription);
      data.append("RecommendingApprovalID", this.approvalID);
      data.append("Amount", this.ppmpRevisedAmount);
      data.append("Balance", parseFloat(this.ppmpData.Amount) - parseFloat(this.ppmpUsedAmount));
      data.append("BudgetID", this.ppmpData.BudgetID);
      data.append("RevisedItemList", JSON.stringify(this.itemsCheckedForRevision));
      data.append("List", JSON.stringify(this.itemsForRevision));
      this.axiosCall("/ppmp/revised/publish", "POST", data).then((res) => {
        if (res.data.status) {
          this.confirmClickCloseMainDialog();
          this.addPPMPDialog = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "Published Successfully!";
          this.fadeAwayMessage.header = "System Message";
          this.$refs.FormRefPublish.reset();
        } else {
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = res.data.message;
          this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
    getApprovalList() {
      this.axiosCall(
              '/get/approval/List',
              'GET'
          )
          .then((res) => {
            this.approvalList = res.data.data;
          })
		},
    getObligationAmount(data){
      let total = 0;
      if(data.PPMPTypeID == 2){
        this.obligations_ndbm.forEach(item => {
          if(item.PPMPItemID == data.ppmpItemID){
            total += parseFloat(item.Amount);
          }
        });
      }
      else if(data.PPMPTypeID == 3){
        this.obligations_lib.forEach(item => {
          if(item.PPMPItemID == data.ppmpItemID){
            total += parseFloat(item.Amount);
          }
        });
      }
        
        return total;
    },
    getFilteredMonths(startDate, endDate) {
      this.filteredMonths = [];
      var start      = startDate.split('-');
      var end        = endDate.split('-');
      var startYear  = parseInt(start[0]);
      var endYear    = parseInt(end[0]);

        for(var i = startYear; i <= endYear; i++) {
          var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
          var startMon = i === startYear ? parseInt(start[1])-1 : 0;
          for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
            var month = j+1;
            var displayMonth = month < 10 ? '0'+month : month;
            if(this.months[displayMonth - 1]){
              this.filteredMonths.push({month: this.months[displayMonth - 1], description: this.months[displayMonth - 1] + ' ' + i})
            }
          }
        }
    },
    itemRowBackground(item) {
      let color = "";
      if(parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty) && item.CategoryID == 21){
        color = "style-1";
      }
      else if(parseFloat(item.Utilized) > 0 && parseFloat(item.Utilized) < parseFloat(item.UnitCost) * parseFloat(item.Qty)){
        color = "style-2";
      }
      else if(item.PRID > 0){
        color = "style-3";
      }
     return color;
  },
  isNegative(num) {
  if (Math.sign(num) === -1) {
    return true;
  }

  return false;
}
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.ppmpModal {
  overflow-x: hidden !important;
}
</style>